class @CountDown
  MILISECONDS_IN_SECOND = 1000

  constructor: (@opts) ->
    if (!@opts)
      throw new Error '[CountDown] Options not found.'
    if (!@opts.callback? || typeof @opts.callback != 'function')
      throw new Error '[CountDown] You need to pass callback and it needs to be a function.'

    @opts.countFrom ?= 10000
    @opts.interval ?= 1000
    @opts.currentTime ?= @opts.countFrom - @opts.interval

  tick: ->
    if @opts.targetElement
      @updateTargetElement(@opts.currentTime / MILISECONDS_IN_SECOND + 1)

    @ticking = setTimeout(=>
      if (@opts.currentTime >= 0)
        @tick()
      else
        @opts.callback.call()
    , @opts.interval)

    @opts.currentTime = @opts.currentTime - @opts.interval

  updateTargetElement: (timeLeft) ->
    @opts.targetElement.html(timeLeft)

  start: ->
    @tick()

  clear: ->
    clearTimeout @ticking
