class @Legend
  constructor: ->
    @init()

  init: ->
    @setCurrentQuestionNumber 1
    @bindings()
    @handleFinishButtonVisibility()

  bindings: ->
    $('#next_button').on 'click', (event) => @onNextButtonClick(event)
    $('#previous_button').on 'click', (event) => @onPreviousButtonClick(event)
    $('.legend').on 'click', '.question-number', (event) =>
      $question = $(event.currentTarget)
      unless $question.hasClass('disabled')
        question_number = $question.attr('data-question-number')
        @setCurrentQuestionNumber question_number

  onNextButtonClick: (event) ->
    if @current_question_number < @questions_count
      @setCurrentQuestionNumber @current_question_number + 1

  onPreviousButtonClick: (event) ->
    if @current_question_number > 1
      @setCurrentQuestionNumber @current_question_number - 1

  getQuestionByNumber: (question_number) ->
    $(".legend .question-number[data-question-number=#{question_number}]")

  getCurrentQuestion: ->
    @getQuestionByNumber @current_question_number

  setCurrentQuestionNumber: (question_number) ->
    @current_question_number = parseInt question_number
    @current_question = @getCurrentQuestion()
    @handleNavigationButtonsVisibility()
    @markQuestionAsCurrent @current_question
    @enableQuestion @current_question
    @afterQuestionNumberClick?(@current_question_number)
    @showCurrentQuestion()

  markQuestionAsCurrent: ($question) ->
    $('.legend .question-number').removeClass 'current'
    $question.addClass 'current'

  showCurrentQuestion: =>
    $('.reasoning-question').hide()
    $("#reasoning_question_#{@current_question_number}").show()

  enableQuestion: ($question) ->
    if $question.hasClass 'disabled'
      $question.removeClass 'disabled'

  handleFinishButtonVisibility: ->
    if @allQuestionsAnswered()
      $('#finish_button').show()
    else
      $('#finish_button').hide()

  allQuestionsAnswered: ->
    $('.legend .question-number.not-answered').length == 0

  touchQuestion: (question_number, answered, form) ->
    $question = @getQuestionByNumber parseInt question_number
    if answered
      $question.removeClass 'not-answered'
    else
      $question.addClass 'not-answered'
    form.touch() if form

  handleNavigationButtonsVisibility: ->
    $next = $('#next_button')
    $previous = $('#previous_button')
    if @current_question_number == 1
      $next.show()
      $previous.hide()
    else if @current_question_number == @questions_count
      $next.hide()
      $previous.show()
    else
      $next.show()
      $previous.show()
