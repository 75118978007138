class @Form
  constructor: ->
    @init()

  init: ->
    @touched = false
    @bindings()

  isTouched: ->
    @touched

  touch: ->
    @touched = true

  bindings: ->
    @questionBindings()

    $(document).on 'ajaxError', (e, xhr) =>
      messageText = if xhr.status is 422
        JSON.parse(xhr.responseText).alert
      else
        @getNextButton().html(@getNextButton().data('resubmit-text'))
        @getDefaultNetworkErrorMessage()
      @hideSpinner()
      @scrollToPageTop()
      @showErrorMessage(messageText)

    $('.form-submit').on 'click', ->
      $(window).off('beforeunload')
      $this = $(this)
      $this.spinner()
      $("##{$this.attr('data-target')}").trigger('submit')
      false

    $('.form-remote-submit').on
      'click': (e) =>
        $form = $("##{$(e.currentTarget).data('target')}")
        $.ajax
          type      : 'PATCH'
          url       : $form.attr('action')
          data      : $form.serialize()
          timeout   : 10000
          beforeSend: @showSpinner
          success   : (data, status, xhr) =>
            if xhr.status is 202 and data.redirect
              window.location.href = data.redirect
              return false
            @successCallback(data)
        false

  scrollToPageTop: ->
    $('#content').scrollTop(0)

  getNextButton: ->
    @nextButton ||= $('#next_button')

  getDefaultNetworkErrorMessage: ->
    $('body').data('network-error')

  showSpinner: =>
    @getNextButton().spinner()
    @getNextButton().html(@getNextButton().data('next-text'))

  hideSpinner: ->
    @getNextButton().spinner('remove')

  isPractice: ->
    $('#practice_info').length > 0

  showErrorMessage: (messageText) ->
    @clearErrorMessage()
    $("<div class='flash-messages alert alert-danger' data-dismiss='alert'>#{messageText}</div>")
      .prependTo('#content')
      .slideDown('normal')

  clearErrorMessage: ->
    $('#content .flash-messages').remove()
