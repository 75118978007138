class @OpenLegend
  constructor: (@context) ->
    @assigns()
    @bindings()

  assigns: ->
    container = $('#questionnaires-open')
    @legend = container.find('.legend')
    @pageLinks = @legend.find('.page')

  bindings: ->
    @pageLinks.on('click', @onPageLinkClick) if @legendActive()

  onPageLinkClick: (event) =>
    event.preventDefault()
    $pageLink = $(event.currentTarget)
    if @pageVisited($pageLink)
      @getNextPageContent($pageLink)

  getNextPageContent: ($pageLink) ->
    $.ajax
      url         : @getPageLinkClickUrl()
      type        : 'PATCH'
      data        : { page_index: $pageLink.data('index') }
      beforeSend  : @showSpinner
      success     : @context.onPageLinkClickSuccessCallback
      complete    : @hideSpinner

  pageVisited: ($pageLink) ->
    $pageLink.hasClass('visited')

  legendActive: ->
   @legend.hasClass('legend-active')

  getPageLinkClickUrl: ->
    @legend.data('url')

  update: (data) =>
    @updateCurrentPageLink(data.current_page_index)
    @updatePreviousPageLink(data.previous_page_index, data.previous_page_answered) if @legendActive()
    @disableLegend() if @isLastPageAndInstructionsOnly()

  updateCurrentPageLink: (currentPageIndex) =>
    @pageLinks.removeClass('current')
    @getPageLink(currentPageIndex).removeClass('visited')
    @getPageLink(currentPageIndex).addClass('current')

  updatePreviousPageLink: (previousPageIndex, previousPageAnswered) =>
    @getPageLink(previousPageIndex).addClass('visited')
    if previousPageAnswered
      @getPageLink(previousPageIndex).addClass('answered')
    else
      @getPageLink(previousPageIndex).removeClass('answered')

  disableLegend: ->
    @pageLinks.off('click')

  getPageLink: (pageIndex) ->
    @pageLinks.filter("[data-index='#{pageIndex}']")

  isLastPageAndInstructionsOnly: ->
    @context.isLastPage() && @context.isOnlyInstructions()

  showSpinner: =>
    @legend.spinner()

  hideSpinner: =>
    @legend.spinner('remove')
