class @OpenForm extends Form
  init: ->
    super()
    @legend = new OpenLegend(@)

  successCallback: (data) ->
    @hideSpinner()
    @clearErrorMessage()
    $('#page').html(data.html)
    @legend.update(data.legend)
    @scrollToPageTop()
    @questionBindings()
    @onPageLoad()

  onPageLinkClickSuccessCallback: (data) =>
    @clearErrorMessage()
    $('#page').html(data.html)
    @legend.update(data.legend)
    @questionBindings()
    @highlightUnansweredQuestions()
    if @anyQuestionsUnanswered()
      @scrollToFirstUnansweredQuestion()
    else
      @scrollToPageTop()
    @onPageLoad()

  onPageLoad: ->
    if @isOnlyInstructions()
      @markPageAsReady()
    else
      @updateNextButtonText()

  scrollToFirstUnansweredQuestion: ->
    $('#content').scrollTop(@firstUnansweredQuestionPosition() - 10)

  firstUnansweredQuestionPosition: ->
    $('#page .question').filter('.unanswered, .development-unanswered').offset().top

  questionBindings: ->
    @sortableRatings()
    @bindKeepDefaultOrderLinks()
    @handleEdition()
    @disableRadioButtonKeyNavigation()
    @disableAnswersIfNotApplicableChecked()

  disableAnswersIfNotApplicableChecked: ->
    $('#page .question .answers > .not-applicable input[type=checkbox]').on('click', (event) ->
      $input = $(event.currentTarget)
      $input
        .closest('.answers')
        .find('> :not(.not-applicable)')
        .find('input, textarea')
        .prop('disabled', $input.prop('checked'))
    )

  disableRadioButtonKeyNavigation: ->
    $('.question input[type=radio]').on('keyup keydown', (event) -> event.preventDefault())

  sortableRatings: ->
    $('.rating .answers').sortable
      axis: 'y'
      tolerance: 'intersect'
      create: @fixSortableAnswersHeight
      stop: ->
        $.each $(this).sortable('toArray'), (index, itemId) ->
          $('#' + itemId).find('.rate').val index
      update: (event, ui) =>
        $link = $(ui.item).closest('.answers').siblings('.keep-default-order')
        @markAsAlreadySorted($link)

  markAsAlreadySorted: ($link) ->
    $link.siblings('ul.answers').find('.already-sorted').val('1')
    $link.hide()

  bindKeepDefaultOrderLinks: ->
    $('.question .keep-default-order').on 'click', (event) =>
      event.preventDefault()
      @markAsAlreadySorted($(event.currentTarget))

  fixSortableAnswersHeight: ->
    $sortable = $(this)
    $sortable.height($sortable.height())

  handleEdition: ->
    $('textarea', @getPageForm()).on 'keyup', (event) => @onTextAreaKeyUp(event)
    $('input[type=radio]', @getPageForm()).on 'click', (event) => @onRadioButtonClick(event)
    $('input[type=checkbox]', @getPageForm()).on 'click', (event) => @onCheckboxClick(event)
    $('.rating .answers', @getPageForm()).on 'sortupdate', (event, ui) => @onRatingAnswerSortUpdate(ui)
    $('.rating .keep-default-order', @getPageForm()).on 'click', (event) => @onRatingKeepOrderLinkClick(event)

  onTextAreaKeyUp: (event) ->
    $textArea = $(event.currentTarget)
    if ($textArea.val() || '').trim()
      @markQuestionAsAnswered($textArea)
    else
      @markQuestionAsUnanswered($textArea)

  onRadioButtonClick: (event) ->
    $radioButton = $(event.currentTarget)
    if @isDevelopmentQuestion($radioButton)
      @markQuestionAsAnswered($radioButton, true)
    else
      @markQuestionAsAnswered($radioButton)

  onCheckboxClick: (event) ->
    $checkbox = $(event.currentTarget)
    $answers = @getQuestionFromElement($checkbox).find('.answers')
    if $answers.find('input[type=checkbox]:checked').length > 0 || ($answers.find('textarea').val() || '').trim()
      @markQuestionAsAnswered($checkbox)
    else
      @markQuestionAsUnanswered($checkbox)

  onRatingAnswerSortUpdate: (ui) ->
    $ratingItem = $(ui.item)
    @markQuestionAsAnswered($ratingItem)

  onRatingKeepOrderLinkClick: (event) ->
    $ratingItem = $(event.currentTarget)
    @markQuestionAsAnswered($ratingItem)

  markQuestionAsAnswered: ($element, is_development_question = false) ->
    question = @getQuestionFromElement($element)
    if is_development_question
      question.removeClass('development-unanswered')
    else
      question.removeClass('unanswered')
    unless question.hasClass('unanswered') || question.hasClass('development-unanswered')
      question.removeClass('highlighted')
    @updateNextButtonText()

  markQuestionAsUnanswered: ($element) ->
    @getQuestionFromElement($element).addClass('unanswered')
    @updateNextButtonText()

  markPageAsUnskippable: ->
    @updateNextButtonTextToNextText()
    @getNextButton().hide()

  markPageAsReady: ->
    if @isFinishingPage()
      @updateNextButtonTextToSendYourAnswersText()
    else
      @updateNextButtonTextToNextText()
    @getNextButton().show()

  markPageAsSkippable: ->
    if @isFinishingPage()
      @updateNextButtonTextToSendYourAnswersText()
    else
      @updateNextButtonTextToSkipText()
    @getNextButton().show()

  isDevelopmentQuestion: ($radioButton) ->
    $radioButton.hasClass('development-item')

  getQuestionFromElement: ($element) ->
    $element.closest('.question')

  updateNextButtonTextToNextText: ->
    @getNextButton().html(@getNextButtonNextText())

  updateNextButtonTextToSkipText: ->
    @getNextButton().html(@getNextButtonSkipText())

  updateNextButtonTextToSendYourAnswersText: ->
    @getNextButton().html(@getNextButtonSendYourAnswersText())

  getPageForm: ->
    $('#page_form')

  getNextButtonNextText: ->
    @getNextButton().data('next-text')

  getNextButtonSkipText: ->
    @getNextButton().data('skip-text')

  getNextButtonSendYourAnswersText: ->
    @getNextButton().data('send-answers-text')

  updateNextButtonText: ->
    if @anyRequiredQuestionsUnanswered()
      @markPageAsUnskippable()
    else if @anyQuestionsUnanswered()
      @markPageAsSkippable()
    else
      @markPageAsReady()

  getCurrentPageLink: ->
    $('.legend .page.current')

  getCurrentPageIndex: ->
    @getCurrentPageLink().data('index')

  getNextPageIndex: ->
    @getCurrentPageIndex() + 1

  getNextPageLink: ->
    $(".legend .page[data-index='#{@getNextPageIndex()}']")

  highlightUnansweredQuestions: ->
    $('#page .question').filter('.unanswered, .development-unanswered').addClass('highlighted')

  anyRequiredQuestionsUnanswered: ->
    $('#page .question.required').filter('.unanswered, .development-unanswered').length > 0

  anyQuestionsUnanswered: ->
    $('#page .question').filter('.unanswered, .development-unanswered').length > 0

  isOnlyInstructions: ->
    @getCurrentPageLink().hasClass('only-instructions')

  isLastPage: ->
    @getCurrentPageLink().is(':last-child')

  isLastButOnePage: ->
    @getNextPageLink().is(':last-child')

  nextPageIsOnlyInstructions: ->
    @getNextPageLink().hasClass('only-instructions')

  isFinishingPage: ->
    @isLastPageAndIsNotOnlyInstructions() || @isLastButOnePageAndNextPageIsOnlyInstructions()

  isLastPageAndIsNotOnlyInstructions: ->
    @isLastPage() && !@isOnlyInstructions()

  isLastButOnePageAndNextPageIsOnlyInstructions: ->
    @isLastButOnePage() && @nextPageIsOnlyInstructions()
