class @ReasoningAnsweringBrowserBehavior
  denyBack: ->
    window.history.forward()

  denyEscape: ->
    $(document).on 'keydown', (event) ->
      if event.keyCode == 27
        event.preventDefault()

  confirmExit: (message) ->
    $(window).on 'beforeunload', -> message

  allowExit: ->
    $(window).off 'beforeunload'
