$ ->
  new OverlayRemoteDataLoader if OverlayRemoteDataLoader.isPresent()

class OverlayRemoteDataLoader
  @SELECTOR = '.overlay-remote-link'

  @isPresent: ->
    $(OverlayRemoteDataLoader.SELECTOR).length > 0

  constructor: ->
    @bindings()

  bindings: ->
    $(document).on('overlayToggle', OverlayRemoteDataLoader.SELECTOR, @onOverlayToggle)

  onOverlayToggle: (event) =>
    $link = $(event.currentTarget)
    unless @alreadyLoaded($link)
      $overlayData = @getOverlayData($link)
      $.ajax
        url       : $link.data('url')
        type      : 'GET'
        data      : $link.data('params') || {}
        dataType  : 'html'
        beforeSend: =>
          @showSpinner($link)
          $overlayData.empty()
        success   : (html) =>
          $overlayData.html(html)
          $link.data('loaded', true) unless $link.hasClass('js-reload-overlay-content')
        complete  : =>
          if $link.data('reload-tree')
            Application.tree()
            Application.overlay.scrollToActiveOverlayResource()
          if $link.data('reload-report-selector')
            Application.overlayReportSelector ||= new OverlayReportSelector()
            Application.overlayReportSelector.reload()
          if $link.data('update-checkboxes')
            new OverlayCheckBoxUpdater().update($link)
          if $link.data('update-checkboxes-open-questionnaire')
            new OverlayOpenQuestionnaireCheckBoxUpdater($link).update()
          if $link.data('initialize-behavioral-competences')
            new Search('#behavioral_competences_search')
            Search.selectActiveBehavioralCompetences()
          @hideSpinner($link)

  getOverlayData: ($link) ->
    targetOverlayId = "#{$link.attr('target')}"
    if ($overlayData = $("##{targetOverlayId} .overlay-data")).length > 0
      $overlayData
    else
      $("##{targetOverlayId} .overlay-data-fixed")

  alreadyLoaded: ($link) ->
    !!$link.data('loaded')

  showSpinner: ($link) ->
    $link.spinner()

  hideSpinner: ($link) ->
    $link.spinner('remove')
