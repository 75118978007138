/**
 * Copyright (C) 2010 Corey Ballou
 */

(function($) {
    var isSelect = false;

    $.fn.spinner = function(options) {
        var opts = $.extend({}, $.fn.spinner.defaults, options);

        return this.each(function() {

            var l=0, t=0, w=0, h=0, shim=0, $s;
            var $this = $(this),
                insertSpinner = true,
                isSelect = $this.is('select');

            // removal handling
            if (options == 'remove' || options == 'close') {
                var $s = $this.data('spinner');
                var o = $this.data('opts');
                // Intentionally left comments for debugging purposes in future
                if (typeof $s != 'undefined') {
                    $s.remove();
                    $this.removeData('spinner').removeData('opts');
                    if (o.hide) $this.css('visibility', '');
                    o.onFinish.call(this);
                    return;
                }
                return;
            }

            // retrieve element positioning
            var pos = $this.position();
            var w = $this.outerWidth();
            var h = $this.outerHeight();
            
            // calculate vertical centering
            if (h > opts.height) shim = Math.round((h - opts.height)/ 2);
            else if (h < opts.height) shim = 0 - Math.round((opts.height - h) / 2);
            t = pos.top + shim + 'px';
            
            // calculate horizontal positioning
            if (opts.position == 'right') {
                l = pos.left + w + 10 + 'px';
            } else if (opts.position == 'left') {
                l = pos.left - opts.width - 10 + 'px';
            } else {
                var fullWidth = $this.offsetParent().outerWidth();
                var leftOffset  = pos.left + $this.outerWidth(true) / 2;
                var halfLoaderWidth = opts.width / 2;

                l = (leftOffset - halfLoaderWidth) / fullWidth * 100 + '%'
            }
            
            // call start callback
            opts.onStart.call(this);
            
            // hide element?
            if (opts.hide) $this.css('visibility', 'hidden');

            var isHidden = (!opts.hide && $this.css('visibility') == 'hidden') || (opts.hide && $this.css('visibility') != 'hidden'),
                alreadyAttached = typeof $this.data('spinner') !== 'undefined';

            /*
                Do not insert spinner if:
                  element is not visible already and it should be visible
                  element is visible and it should be hidden
                  spinner is already attached to this element
            */
            if ( isHidden || alreadyAttached ) {
                insertSpinner = false;
            }

            if ( insertSpinner ) {
                $s = $("<em>").addClass('spinner').css({ left: l, top: t }).insertAfter($this)
            }
        
            // removal handling
            $this.data('spinner', $s).data('opts', opts);
        });
    };
    
    // default spinner options
    $.fn.spinner.defaults = {
        position    : isSelect ? 'right' : 'center' // left, right, center
        , height    : 16                            // height of spinner img
        , width     : 16                            // width of spinner img
        , hide      : isSelect ? false : true       // whether to hide the elem
        , onStart   : function(){ }                 // start callback
        , onFinish  : function(){ }                 // end callback
    };
})(jQuery);
