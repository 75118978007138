class @ValuesQuestionnaire
  constructor: ->
    @_assignVariables()
    @_bindEvents()
    new ValuesTimeCounter

  _assignVariables: =>
    @$choices = $('.js-values-choice')

  _bindEvents: =>
    @$choices.on 'click', @_choiceClicked
    @$choices.find(':radio').on 'change', @_choiceChanged

  _choiceClicked: (event) =>
    $choice = $(event.currentTarget)
    $choice.find(':radio').prop('checked', true).trigger('change')

  _choiceChanged: (event) =>
    $('.js-values-choice').each (_, choice) ->
      $choice = $(choice)
      $choice.toggleClass('values-choice-selected', $choice.find(':radio').is(':checked'))
