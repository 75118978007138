require('legacy/jquery/_jquery_questionnaires');
require('legacy/answering-process');
require('legacy/overlay_remote_data_loader');
require('legacy/messages/plugin');

import { throttle } from 'throttle-debounce';

$(function() {
  function resizeChangeLanguageOverlay() {
    let $changeLanguageOverlay = $('#overlay_switch_questionnaire_language'),
        windowHeight = $(window).height();

    $('.overlay-data', $changeLanguageOverlay).height(windowHeight - 130);
  }

  resizeChangeLanguageOverlay();

  $(window).on('resize', throttle(100, function() {
    resizeChangeLanguageOverlay();
  }));
});
