class @ValuesTimeCounter extends @TimeCounter
  SELECTOR = '#time_counter'
  CONTAINER_SELECTOR = '.js-time-counter-container'
  FORM_SELECTOR = '#values-answer'
  FINISH_BUTTON_SELECTOR = '#finish_button'

  assigns: =>
    @container = $(SELECTOR)
    @containerWrapper = $(CONTAINER_SELECTOR)

  initialize: =>
    if @container.length > 0
      @toggleContainerWrapper()
      @setup()
      @start()

  timeTick: =>
    @container.html(@getFormattedTime(@remainingTime))
    @container.data('time', parseInt(@remainingTime))
    if @remainingTime == 0
      @stop()
      @removeFinishButton()
      @submitForm()
    else
      @remainingTime -= 1
      @remainingTime = 0 if @remainingTime < 0
      @pulsate() if @remainingTime < 30
      @toggleContainerWrapper()

  toggleContainerWrapper: =>
    if @remainingTime < 600
      @containerWrapper.show()
    else
      @containerWrapper.hide()

  submitForm: =>
    @getForm().trigger('submit')

  getForm: ->
    $(FORM_SELECTOR)
