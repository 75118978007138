class @TimeCounter
  SELECTOR = '#time_counter'
  FORM_SELECTOR = '#reasoning_answering_form'
  FINISH_BUTTON_SELECTOR = '#finish_button'

  constructor: ->
    @assigns()
    @initialize()

  assigns: =>
    @container = $(SELECTOR)
    @browserBehavior = new ReasoningAnsweringBrowserBehavior

  confirmExit: (alreadyOn) =>
    unless alreadyOn
      @browserBehavior.confirmExit(@getConfirmNavigationMessage())

  initialize: =>
    if @container.length > 0
      @browserBehavior.denyEscape()
      @setup()
      @start()

  setup: =>
    @remainingTime = @getRemainingTime()
    @timer = $.timer @timeTick
    @stopOnFinish()

  timeTick: =>
    @container.html(@getFormattedTime(@remainingTime))
    @container.data('time', parseInt(@remainingTime))
    if @remainingTime == 0
      @browserBehavior.allowExit()
      @stop()
      @removeFinishButton()
      @submitAnswers()
    else
      @browserBehavior.denyBack()
      @remainingTime -= 1
      @remainingTime = 0 if @remainingTime < 0
      @pulsate() if @remainingTime < 30

  pulsate: =>
    @container.effect('pulsate', { times: 1 }, 2000)

  start: =>
    @timer.set { time: 1000, autostart: true }

  stopOnFinish: =>
    @getFinishButton().on('click', @stop)

  stop: =>
    @timer.stop()

  getRemainingTime: =>
    parseInt(@container.data('time'))

  getForm: ->
    $(FORM_SELECTOR)

  getFinishButton: ->
    $(FINISH_BUTTON_SELECTOR)

  getTimeoutHiddenInput: ->
    $('<input type=\'hidden\'>').attr({ id: 'timeout', name: 'timeout', value: '1' })

  getConfirmNavigationMessage: =>
    @container.parent('[data-confirm-navigation]').data('confirm-navigation')

  removeFinishButton: =>
    @getFinishButton().remove()

  submitAnswers: =>
    $form = @getForm()
    @getTimeoutHiddenInput().appendTo($form)
    $form.trigger('submit')

  getFormattedTime: (time) =>
    minutes = parseInt(time / 60)
    seconds = parseInt(time - minutes * 60)
    "#{@pad(minutes)}:#{@pad(seconds)}"

  pad: (number) ->
    numberString = number.toString()
    if numberString.length < 2
      '0' + numberString
    else
      numberString
