class @DpaQuestionnaire extends Questionnaire
  init: ->
    super()
    @form = new DpaForm

  class DpaForm extends Form
    successCallback: (data) ->
      @clearErrorMessage()
      @getNextButton().spinner('remove')
      @changeForm()

    getCurrentForm: ->
      $('.question-form.current')

    changeForm: ->
      $nextForm = @getCurrentForm().removeClass('current').next()
      if $nextForm.length > 0
        formId = $nextForm.attr('id')
        $nextForm.addClass('current')
        @getNextButton().data('target', formId)
        $('#current_question_index').html(formId.match(/\d+$/)[0])

    getRadioSiblingClass: ($radio) ->
      if $radio.hasClass('most') then 'least' else 'most'

    getRadioSiblings: ($radio) ->
      @getCurrentForm().find("input[type=radio][class=#{@getRadioSiblingClass($radio)}]")

    questionBindings: ->
      $('.question-form input[type=radio]').on
        'click': @onRadioClick
        'keyup keydown': @onRadioKeyPress

    onRadioClick: (event) =>
      $radio = $(event.currentTarget)
      @getRadioSiblings($radio).filter(':disabled').prop('disabled', false)
      @getRadioSiblings($radio).filter("[value=#{$radio.attr('value')}]").prop('disabled', true)

    onRadioKeyPress: (event) =>
      event.preventDefault()
