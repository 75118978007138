class @OverlayLink
  constructor: (link) ->
    this.init link

  init: (link) ->
    @link = $(link)
    @target = $("##{@link.attr('target')}")
    this.bindings()

  bindings: ->
    @link.on 'click', =>
      @link.trigger('overlayToggle')
      @target.toggle()
      false

    $('.cancel', @target).on 'click', =>
      @target.hide()
      false
